@charset "UTF-8";
/*主题色*/
/*联系我们背景颜色*/
/*灰色字体*/
/*灰色边框色*/
.linkBg {
  padding-left: 0;
  margin-left: 15px;
  background: #f7f7f7;
  display: flex;
  align-items: center; }

@media (max-width: 576px) {
  .linkBg {
    padding-left: 15px;
    margin-left: 0; } }

.icon {
  border-radius: 10px;
  width: 100%;
  cursor: pointer; }

.linkView {
  color: #333;
  font-size: .875rem;
  background: #f7f7f7;
  margin-right: -15px;
  padding: 2rem 3rem 2rem 1rem; }

.linkTitleView {
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  padding-bottom: 15px;
  font-size: 1.2rem; }

.linkTitle {
  color: #409EFF;
  border-bottom: 2px solid #409EFF;
  padding-bottom: 13px; }

.company {
  font-weight: bold;
  margin: 20px 0;
  color: #333; }

.linkDD {
  color: #333;
  font-weight: bold;
  line-height: 2rem; }
