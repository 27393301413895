@charset "UTF-8";
/*导航栏背景色*/
/*主题色*/
/*主题字体色*/
/*导航项背景色*/
/*导航项字体颜色*/
/*右侧toggle按钮边框颜色*/
/*footer背景色*/
/*footer字体颜色*/
/*灰色边框色*/
/*菜单选中颜色*/
.wrapper {
  background: #fff !important;
  padding: 1.38rem 0; }

* {
  font-family: "Microsoft YaHei","微软雅黑","MicrosoftJhengHei","华文细黑",STHeiti,MingLiu !important; }

.navbar-dark .navbar-brand {
  color: #2B4C65;
  padding: 0;
  margin: 0; }

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #2B4C65; }

.navbar-dark .navbar-nav .nav-link {
  color: #2B4C65;
  cursor: pointer;
  margin-left: 20px; }

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: #409EFF;
  background-color: #F9F9F9;
  border-bottom: 2px solid #409EFF; }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
  background-color: #409EFF;
  border-radius: 8px; }

.navbar-dark .navbar-toggler {
  border-color: rgba(43, 76, 101, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url(./lines.png); }

.navbar-dark .navbar-text a {
  color: #2B4C65; }

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #2B4C65; }

.nav-link {
  padding: 0 !important;
  width: 100%;
  height: 2.2rem;
  text-align: center;
  line-height: 2.2rem; }

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    width: 6rem; } }

.navRight {
  justify-content: flex-end;
  position: relative; }

.content {
  width: 100%;
  padding: 2.6875rem 0; }

.carousel-indicators li:hover {
  cursor: pointer; }

.carousel-control-next-icon {
  width: 1.875rem;
  height: 1.875rem;
  background-image: url(../static/rightPage.png); }

.carousel-control-prev-icon {
  width: 1.875rem;
  height: 1.875rem;
  background-image: url(../static/leftPage.png); }

.footerView {
  background-color: #373838;
  color: #fff;
  position: relative; }

.footerView .content {
  padding-top: 2rem; }

.footTitle {
  margin-top: 20px;
  margin-bottom: 30px;
  display: block;
  height: 1rem; }

.footLink {
  text-decoration: none;
  color: #fff;
  display: block;
  margin-bottom: 15px;
  font-size: .75rem;
  line-height: 1rem; }

.footLink:hover {
  color: #409EFF;
  text-decoration: none; }

.codeIcon {
  border: 1px solid #fff;
  border-radius: 2px;
  width: 60%; }

.iconDesc {
  width: 60%;
  margin-top: 1rem;
  text-align: center;
  font-size: .875rem; }

.siteDesc {
  color: #666;
  display: flex;
  flex-direction: row;
  justify-content: center;
  bottom: 1rem;
  left: 0;
  width: 100%;
  height: 20px;
  font-size: .75rem; }

.imgView {
  margin-top: -5px;
  width: 20px;
  height: 20px;
  margin-right: 5px; }

.siteDesc1 {
  color: #666;
  font-size: .75rem;
  height: 20px; }

.aboutView {
  margin-bottom: 25px; }

.titleView {
  text-align: center;
  margin-bottom: 2.6875rem; }

.titleView .title {
  position: relative;
  font-size: 1.25rem;
  margin-bottom: .5rem; }

.titleView .title:before, .titleView .title:after {
  content: '';
  position: absolute;
  top: 80%;
  background: #409EFF;
  width: 5%;
  height: 2px; }

.titleView .title:before {
  left: 38%; }

.titleView .title:after {
  right: 38%; }

@media (max-width: 576px) {
  .titleView .title:before, .titleView .title:after {
    width: 10%; }
  .titleView .title:before {
    left: 16%; }
  .titleView .title:after {
    right: 16%; } }

.titleView .desc {
  color: #666; }

.menuArea {
  border: 1px solid #409EFF;
  color: #666;
  text-align: center;
  border-radius: 5px; }

.menuArea li {
  padding: 1rem 0;
  cursor: pointer; }

.titleName {
  background: #409EFF;
  color: #fff; }

.titleItem {
  border-bottom: 1px solid #ccc;
  word-break: break-all; }

.titleItemActive, .titleItem:hover {
  background-color: #E2F0FF;
  position: relative;
  word-break: break-all; }

.titleItemActive:after {
  content: "";
  border-width: .5rem 0 .5rem .5rem;
  border-style: solid;
  border-color: transparent transparent transparent #409EFF;
  position: absolute;
  top: 33%;
  right: -.5rem; }

.menuArea li:last-child {
  border: none; }

/*新闻详情部分*/
.newsDetailArea {
  color: #666; }

/* 标题 */
.newsDetailArea div {
  color: #000;
  text-align: center;
  font-size: 1.4rem;
  margin-top: 0; }

/* 小标题 */
.newsDetailArea b {
  color: #000;
  font-size: 1.1rem;
  margin-top: 2rem;
  display: block; }

.newsDetailArea img {
  display: block;
  margin: 2rem auto 0;
  max-width: 100%; }

.newsDetailArea p {
  line-height: 1.5rem;
  margin-top: 2rem;
  text-indent: 2rem; }

/* 图表标题 */
.newsDetailArea span {
  text-align: center;
  margin-top: 2rem;
  display: block; }

.newsDetailArea p a {
  display: inline-block;
  text-indent: 0;
  color: #409EFF; }
