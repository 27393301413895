@charset "UTF-8";
/*主题色*/
/*主题字体色*/
/*公司背景颜色*/
/*荣誉资质字体阴影色*/
/*荣誉资质蓝色字体*/
/*荣誉资质红色字体*/
/*荣誉资质紫色字体*/
/*关于我们部分公司背景图片*/
/*荣誉资质部分背景图片*/
.aboutUsBg {
  width: 100%; }

.companyContent {
  margin-top: 50px; }

.companyView {
  background: #F4F9FF;
  padding: 40px 30px 25px 30px;
  border-radius: 10px; }

.companyDescView {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px; }

.companyTitle {
  color: #409EFF;
  text-align: center;
  margin: 2rem auto 3rem;
  font-weight: 600;
  font-size: 1.2rem; }

.companyDesc {
  line-height: 25px;
  color: #666;
  text-indent: 2rem; }

.companyIconView {
  margin-bottom: 20px; }

.cultureIcon {
  width: 50%;
  margin: 0 auto;
  display: block; }

.cultureTitle {
  color: #409EFF;
  margin: 20px auto;
  text-align: center;
  font-size: 1.2rem; }

.cultureDesc {
  color: #666;
  line-height: 25px;
  text-align: center; }

.hornorContentView {
  padding-bottom: 0; }

.hornorView {
  background: url("../../static/honorBg.jpg");
  background-size: 100% 100%;
  text-align: center;
  text-shadow: 2px 2px 2px #777; }

.hornorNum {
  font-size: 3rem; }

.hornorTec {
  color: #45BEEB;
  padding: 1.5rem 0; }

.hornorPro {
  color: #F5749B;
  padding: 1.5rem 0; }

.honorItem {
  color: #AF8BDE;
  padding: 1.5rem 0; }

.sideAwards {
  width: 70%;
  margin: 10% auto;
  display: block; }

.centerAwards {
  display: block;
  width: 100%;
  margin-left: 15px;
  margin-right: 15px; }

.awardsView {
  margin-bottom: 3rem; }
