@charset "UTF-8";
/*主题色*/
/*主题字体色*/
/*产品标题背景色*/
/*白色字体，包括产品中心标题浮动时的颜色，新闻动态内容部分的颜色，关于我们部分的字体颜色*/
/*灰色边框色，包括解决方案内容块的颜色和新闻每行下面的边框色*/
/*首页部分关于我们背景图片*/
/*荣誉资质部分背景图片*/
.bannerIcon {
  width: 100%;
  height: auto; }

.proTitle {
  background-color: #409EFF;
  color: #fff;
  width: 100%;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 15px; }

.proIcon {
  width: 100% !important;
  border: 1px solid #409EFF;
  cursor: pointer;
  margin-bottom: 20px; }

.proList:hover .proTitle {
  margin-top: -5px;
  width: 100%;
  font-size: 15px;
  margin-left: -10px;
  padding: 0 5px;
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: content-box; }

.proList:hover .proIcon {
  margin-bottom: -20px;
  margin-left: -10px;
  box-sizing: content-box; }

.resolveContent {
  background: url("../../static/resolveBg.jpg"); }

.resolveView {
  border: 1px solid #ccc;
  border-radius: 10px;
  cursor: pointer;
  padding: 0;
  text-align: center;
  color: #666;
  margin-bottom: 25px; }

.resolveView:hover {
  padding: 10px;
  background: #fff;
  margin-bottom: -20px; }

.resolveView:hover .resolveIcon {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.resolveIcon {
  width: 100%;
  height: 300px;
  display: block;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px; }

.resolveTitle {
  padding-bottom: 15px;
  border-bottom: 1px dashed #409EFF;
  width: 100%;
  margin: 25px auto 0; }

.resolveDesc {
  margin: 15px 25px;
  font-size: .75rem; }

.newsTitle {
  cursor: pointer;
  color: #409EFF;
  font-weight: bold; }

.more {
  color: #409EFF;
  font-size: .75rem;
  text-align: right;
  cursor: pointer;
  font-weight: bold;
  line-height: 1rem; }

.newsView {
  padding: 0 30px;
  margin-bottom: 20px; }

.newsMedia {
  border-radius: 10px;
  margin: 15px 0;
  height: 130px;
  background-color: #409EFF;
  color: #fff;
  cursor: pointer; }

.newsPic {
  width: 25%;
  height: 100%;
  border-radius: 10px; }

.newsBody {
  padding: 20px 10px;
  width: 75%; }

.newsBody h5 {
  margin-bottom: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.newsBodyDesc {
  font-size: .875rem;
  line-height: 20px;
  overflow: hidden;
  height: 60px; }

.newsListView li {
  padding: 15px 0;
  border-bottom: 1px dashed #ccc; }

.newsListView a {
  text-decoration: none;
  color: #666;
  font-size: .875rem;
  clear: both;
  position: relative;
  padding-left: 15px; }

.newsListView a:hover {
  color: #409EFF; }

.newsListTitle::before {
  display: block;
  content: '';
  border-width: 6px 6px 6px 6px;
  border-style: solid;
  border-color: transparent transparent transparent #409EFF;
  position: absolute;
  left: 0;
  top: 0px; }

.newsListTime {
  float: right; }

.newsCntent {
  padding-bottom: 0; }

.advanIcon {
  border-radius: 10px;
  cursor: pointer;
  width: 100%; }

.advanImgView {
  margin-bottom: 30px; }

.advanListView {
  margin-bottom: -30px; }

.advanList {
  border: 1px solid #409EFF;
  border-radius: 5px;
  padding: 20px 40px;
  color: #666;
  line-height: 24px;
  font-size: .875rem;
  cursor: pointer;
  margin-bottom: 30px; }

.advanTitle {
  color: #409EFF;
  text-align: center;
  margin: 0 auto 20px;
  font-size: 1rem; }

.advanTitle img {
  margin-right: 5%;
  vertical-align: middle; }

.aboutUsContent {
  background: url("../../static/aboutus.jpg");
  color: #fff;
  text-align: center; }

.aboutUsContent .title, .aboutUsContent .desc {
  color: #fff; }

.aboutUsContent .title::before, .aboutUsContent .title::after {
  background: #fff; }

.aboutDesc {
  line-height: 25px; }

.aboutNum {
  width: 5.625rem;
  height: 5.625rem;
  text-align: center;
  border: 2px solid #fff;
  margin: 35px auto;
  border-radius: 2.8125rem;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 5.625rem;
  cursor: pointer; }

.sgsDownload {
  color: #409EFF;
  cursor: pointer;
  padding-bottom: 5px;
  display: inline; }

.rowAround {
  justify-content: space-around; }
