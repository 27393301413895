@charset "UTF-8";
/*灰色边框色*/
/*主题色*/
/*主题字体色*/
/*表格第一行文字颜色*/
/*表格颜色*/
.contentArea {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: #666; }

@media (max-width: 576px) {
  .contentArea {
    padding-top: 2rem;
    padding-bottom: 2rem; } }

.prodTitle {
  color: #409EFF;
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 1rem; }

.prodContent {
  padding-left: 1rem;
  padding-right: 1rem; }

.prodDesc {
  line-height: 1.4rem;
  padding-left: 1rem;
  padding-right: 1rem; }

.prodTable {
  margin-top: 2rem;
  text-align: center;
  width: 100%;
  table-layout: fixed; }

.prodTable td {
  padding: 1rem;
  border-right: 1px solid #fff;
  border: 1px solid #fff;
  line-height: 1.2rem;
  word-break: break-all; }

.prodTableTitle {
  color: #fff;
  background: #409EFF; }

.prodTableItem td {
  background-color: #E2F0FF; }

.prodTableItemRight td {
  background-color: #E2F0FF;
  text-align: left; }

.themeColor {
  color: #409EFF;
  display: block;
  padding-bottom: 2px;
  box-sizing: border-box;
  margin: 0 5px; }

.themeColorLeft {
  text-align: left; }

table tr:first-child td:first-child {
  border-top-left-radius: 5px; }

table tr:first-child td:last-child {
  border-top-right-radius: 5px; }

table tr:last-child td:first-child {
  border-bottom-left-radius: 5px; }

table tr:last-child td:last-child {
  border-bottom-right-radius: 5px; }

.sceneTitle {
  color: #409EFF;
  background: url("../../static/line.png") right 1rem no-repeat;
  margin-top: 3rem;
  padding-bottom: 1rem;
  padding-left: 1rem; }

.resolveSceneTitle {
  margin-top: 1rem; }

.sceneView {
  padding: 2rem 0; }

.sceneIcon {
  display: block;
  height: 150px;
  margin: 0 auto; }

.scenedd {
  text-align: center;
  color: #409EFF;
  margin-top: 20px; }

.packagedd {
  text-align: center; }

.prodChar {
  margin-top: 2rem; }

.prodChar li {
  position: relative;
  margin-bottom: 1rem;
  line-height: 1.5rem;
  padding: 0 1.3rem 0 3.3rem;
  word-break: break-all; }

.prodChar li:before {
  content: '';
  position: absolute;
  top: 5px;
  left: 30px;
  width: 10px;
  height: 10px;
  background: #409EFF;
  border-radius: 5px; }

.applyIcon {
  width: 80%;
  margin: 3rem auto;
  display: block; }

.mg_top {
  margin-top: 2rem;
  padding: 0 1rem;
  line-height: 1.5rem; }

.relProTitle {
  font-weight: bold;
  color: #000;
  padding-left: 1rem; }

a {
  display: block; }

.proBanner {
  width: 100%; }

.proItem {
  display: inline-block;
  width: auto;
  margin-right: 10px; }

.envContent {
  text-indent: 30px;
  line-height: 25px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: -15px; }
